// src/directive/track/index.js
import Vue from 'vue';
import { sendAction } from '../utils/hyper';

Vue.directive('track', {
  bind(el, binding) {
    // 获取指令参数
    const traceParams = binding.value;
    el.addEventListener(
      'click',
      function () {
        sendAction(traceParams);
      },
      true,
    );
  },
});
