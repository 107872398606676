export default [
  {
    name: 'ent-gen',
    path: '/ent-gen',
    component: () => import('../views/product'),
    meta: {
      title: '企业通用类应用 | B2BGO官网',
      content: {
        title: '企业通用类应用 | B2BGO官网',
        description: '企业通用类应用覆盖协同办公、营销获客、人力资源、电商中台、财税费控、技术支持、开发者服务、垂直行业、智慧服务9大应用场景，为企业提供应用服务。'
      }
    },
    children: [
      {
        path: '/ent-gen/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'big-data',
    path: '/big-data',
    component: () => import('../views/product'),
    meta: {
      title: 'AI&大数据类应用 | B2BGO官网',
      content: {
        title: 'AI&大数据类应用 | B2BGO官网',
        description: 'AI&大数据类应用覆盖大数据应用、AI、数据标注、知识管理、工业配料优化、农业大数据6大应用场景，为企业提供应用支持。'
      }
    },
    children: [
      {
        path: '/big-data/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'basic',
    path: '/basic',
    component: () => import('../views/product'),
    meta: {
      title: '基础软件应用&服务 | B2BGO官网',
      content: {
        title: '基础软件应用&服务 | B2BGO官网',
        description: '基础软件应用覆盖基础软件、安全、服务3大类应用场景，为企业提供应用服务支持。'
      }
    },
    children: [
      {
        path: '/basic/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'hardware',
    path: '/hardware',
    component: () => import('../views/product'),
    meta: {
      title: '硬件云服务 | B2BGO官网',
      content: {
        title: '硬件云服务 | B2BGO官网',
        description: '软件云服务覆盖智慧办公、设备上云2大应用场景，为企业提供云上应用服务支持。'
      }
    },
    children: [
      {
        path: '/hardware/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'innovation',
    path: '/innovation',
    component: () => import('../views/product'),
    meta: {
      title: '创新宇宙&元宇宙类应用 | B2BGO官网',
      content: {
        title: '创新宇宙&元宇宙类应用 | B2BGO官网',
        description: '创新应用&元宇宙服务覆盖3D展示、数字人、AR协作、区块链4大应用场景，为企业提供智能化创新服务。'
      }
    },
    children: [
      {
        path: '/innovation/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'saas-pkg',
    path: '/saas-pkg',
    component: () => import('../views/product'),
    meta: {
      title: 'SaaS Package | B2BGO官网',
      content: {
        title: 'SaaS Package | B2BGO官网',
        description: 'SaaS Package服务覆盖企业初创、企业营销、企业管理3大应用场景，为企业提供创新发展服务支持。'
      }
    },
    children: [
      {
        path: '/saas-pkg/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'manufacture',
    path: '/manufacture',
    component: () => import('../views/product'),
    meta: {
      title: '制造&专精特新 | B2BGO官网',
      content: {
        title: '制造&专精特新 | B2BGO官网',
        description: '制造专精特新覆盖研发设计、生产管理、供应链管理、销售管理、其他服务5大应用场景，为企业提供制造业应用支持。'
      }
    },
    children: [
      {
        path: '/manufacture/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'edu',
    path: '/edu',
    component: () => import('../views/product'),
    meta: {
      title: '教育应用 | B2BGO官网',
      content: {
        title: '教育应用 | B2BGO官网',
        description: '教育类应用覆盖开发者服务、职业教育、教育信息化、普教、在线教育5大应用场景，为企业提供教育应用。'
      }
    },
    children: [
      {
        path: '/edu/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'internet',
    path: '/internet',
    component: () => import('../views/product'),
    meta: {
      title: '互联网&文娱 | B2BGO官网',
      content: {
        title: '互联网&文娱 | B2BGO官网',
        description: '互联网文娱类覆盖直播、点播、转码、3D展示、数字人、AR协作等4大应用场景，为企业提供文娱类应用支持。'
      }
    },
    children: [
      {
        path: '/internet/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'cross-border',
    path: '/cross-border',
    component: () => import('../views/product'),
    meta: {
      title: '跨境电商&企业出海 | B2BGO官网',
      content: {
        title: '跨境电商&企业出海 | B2BGO官网',
        description: '跨境电商应用主要助力企业出海，为企业提供海外发展的软件应用支持。'
      }
    },
    children: [
      {
        path: '/cross-border/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'new-retail',
    path: '/new-retail',
    component: () => import('../views/product'),
    meta: {
      title: '新零售 | B2BGO官网',
      content: {
        title: '新零售 | B2BGO官网',
        description: '新零售应用主要提供零售业相关应用，帮助企业在零售领域快速实现数字化。'
      }
    },
    children: [
      {
        path: '/new-retail/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'ent-service',
    path: '/ent-service',
    component: () => import('../views/product'),
    meta: {
      title: '企业服务 | B2BGO官网',
      content: {
        title: '企业服务 | B2BGO官网',
        description: '企业服务类应用主要有华为乾坤云服务、CloudFabric Easy极简数据中心、阿帕WMS仓储管理解决方案、环信全渠道智能客服、环信即时通讯云、环信实时音视频通信平台、环信机器人、WMS仓储管理系统等，查看更多企业服务应用。'
      }
    },
    children: [
      {
        path: '/ent-service/:id',
        component: () => import('../views/product'),
      },
    ],
  },
];
