import HyperWebAnalytics from '@hypers/hwa-sdk';
import Vue from 'vue';

const hwa = new HyperWebAnalytics({
  jsUrl: '//hw-t.hypers.com.cn/hwt.js?v=1.10',
});
hwa.create('202', {
  auto_track: true,
  heatmap_url: '//hw-analytics.hypers.com.cn/static/hwt-heatmap.js',
  env: { inst: 'hw' },
  api_url: '//hw-t.hypers.com.cn',
});

export async function sendAction(opts) {
  console.log('上报点击埋点', opts);
  hwa.sendAction(opts.eventType, opts.params);
}
export async function sendPageview(opts) {
  console.log('opts: ', opts);
  // 传递 title 与 url
  hwa.sendPageview({
    title: opts.meta.title || '828 B2B企业节',
    url: opts.path,
  });
}

Vue.prototype.$hwa = hwa;
