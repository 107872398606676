import Vue from "vue";
import App from "./App.vue";
import "./utils/hyper";
import router from "./router";
import "./directive/track";
import VideoPlayer from "vue-video-player";

require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
