<template>
  <div :class="['fixed-tool', goTopShow && 'show']">
    <ul>
      <li
        class="tool-item"
        v-track="{
          eventType: 'index_online_counseling_click',
          params: {},
        }"
      >
        <div class="iconfont icon-tijiaoxuqiu"></div>
        <div class="label">咨询</div>
        <a href="https://e-campaign.huawei.com/events2/registerPc/pcRegister.html?eventId=8251">
          <div class="pop">
            <h3>咨询与建议</h3>
            <p>您的每一个需求，我们都认真对待</p>
          </div>
        </a>
      </li>
      <!-- <li class="tool-item">
        <div class="iconfont icon-zaixianzixun"></div>
        <div class="label">在线咨询</div>
        <div class="pop">
          <h3>咨询热线</h3>
          <p>您的每一次提问，都能匹配最合适的答案</p>
        </div>
      </li>
      <li class="tool-item">
        <div class="iconfont icon-zixunrexian-1"></div>
        <div class="label">咨询热线</div>
        <div class="pop">
          <h3>咨询热线</h3>
          <a href="tel:950808"
            ><p><b>950808</b> 转1</p></a
          >
          <a href="tel:400955988"
            ><p><b>400-955-988</b> 转1</p></a
          >
        </div>
      </li> -->
    </ul>
    <div class="go-top" @click="goTop">
      <div class="label">
        <div class="iconfont icon-shouqi"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scrollTop: '',
      goTopShow: false,
    };
  },
  watch: {
    scrollTop() {
      if (this.scrollTop > 500) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    },
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop > 500) {
        this.goTopShow = true;
      }
    },
    goTop() {
      let timer = null,
        _that = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop -= 500;
          document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
<style scoped lang="less">
.fixed-tool {
  position: fixed;
  bottom: 50px;
  right: 32px;
  z-index: 5;
  // transform: translateY(-50%);
  width: 54px;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  &.show {
    opacity: 1;
    visibility: visible;
  }

  ul {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.09);
    border-radius: 4px;
  }
  .go-top {
    margin-top: 10px;
    width: 100%;
    height: 54px;
    background: #e6002e;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    .iconfont {
      font-size: 24px;
    }
    img {
      width: 12px;
      display: block;
    }
  }
  .tool-item {
    padding: 10px 0;
    text-align: center;
    font-weight: 400;
    font-size: 10px;
    color: #2a2a2a;
    a {
      color: #2a2a2a;
    }
    .iconfont {
      font-size: 24px;
    }
    .label {
      font-size: 12px;
      padding-top: 6px;
      transform: scale(0.8);
    }
    position: relative;

    .pop {
      color: #2a2a2a;
      position: absolute;
      right: 70px;
      top: 50%;
      transform: translateY(-50%);
      width: 248px;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
      border-radius: 2px;
      padding: 12px 16px;
      text-align: left;
      transition: all 0.5s;
      visibility: hidden;
      opacity: 0;
      &::after,
      &::before {
        display: block;
        content: '';
        position: absolute;
        right: -12px;
        top: 50%;
        transform: translateY(-50%);
        border: 6px solid transparent;
        border-left: 6px solid #fff;
      }
      &::after {
        z-index: 1;
      }
      &::before {
        right: -6px;
        border-width: 1px;
        box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
      }
      h3 {
        font-weight: 700;
        font-size: 14px;
        line-height: 1;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
        color: #666;
        padding-top: 8px;
        b {
          color: #e6002e;
          padding-right: 5px;
          font-weight: normal;
        }
      }
    }
    &:hover {
      a {
        color: #e6002e;
      }
      color: #e6002e;

      .pop {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  @media (max-width: 991px) {
    width: 0.36rem;
    right: 0.16rem;
    bottom: 0.16rem;
    .tool-item {
      padding: 0.06rem 0;
      .iconfont {
        font-size: 0.2rem;
      }
      .label {
        display: none;
      }
      img.icon {
        width: 0.16rem;
      }
    }
    .go-top {
      height: 0.36rem;
      border-radius: 2px;
      margin-top: 0.08rem;
      .iconfont {
        font-size: 0.24rem;
      }
      img {
        width: 0.08rem;
      }
    }
  }
}
</style>
