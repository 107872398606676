import router from "../router";

const route = router.options.routes;
let allSource = null; // 所有数据
let dataUrls = null;

export async function searchKeyWord(keyword) {
  if (!allSource) {
    dataUrls = getRouteSourceUrl(route);
    const source = await querySources(dataUrls);
    const productSource = await queryProduct();
    allSource = Object.assign(productSource, source);
  }
  const res = [];
  Object.keys(allSource).forEach((k) => {
    if (Array.isArray(allSource[k])) {
      allSource[k].forEach((i) => {
        i.tit = i.tit || i.name || i.title;
        const arr = dataUrls[k].split("/");
        const path = arr[arr.length - 2];
        if (i.tit.search(keyword) > -1) {
          res.push({
            ...i,
            path: k.split("/").length > 1 ? "product" + k : path,
          });
        }
      });
    } else {
      Object.keys(allSource[k]).forEach((k2) => {
        if (Array.isArray(allSource[k][k2])) {
          allSource[k][k2].forEach((i) => {
            const arr = dataUrls[k].split("/");
            const path = arr[arr.length - 2];
            i.tit = i.tit || i.name || i.title;
            if (i.tit.search(keyword) > -1) {
              res.push({
                ...i,
                path: k.split("/").length > 1 ? "product" + k : path,
              });
            }
          });
        } else {
          allSource[k][k2].tit =
            allSource[k][k2].tit ||
            allSource[k][k2].name ||
            allSource[k][k2].title;
          const arr = dataUrls[k].split("/");
          const path = arr[arr.length - 2];
          if (allSource[k][k2].tit.search(keyword) > -1) {
            res.push({
              ...allSource[k][k2],
              path: k.split("/").length > 1 ? "product" + k : path,
            });
          }
        }
      });
    }
  });
  return res;
}

async function querySources(data) {
  const keys = Object.keys(data);
  const res = {};
  for (const key of keys) {
    const json = await fetch(data[key]);
    const source = await json.json();
    res[key] = source;
  }
  return res;
}

function getRouteSourceUrl(data) {
  const res = {};
  data.forEach((i) => {
    if (i.meta && i.meta.dataUrl) {
      res[i.name] = i.meta.dataUrl;
    }
    if (i.children) {
      Object.assign(res, getRouteSourceUrl(i.children));
    }
  });
  return res;
}

async function queryProduct() {
  const res = {};
  const menuRoutes = await fetch(`/assets/menu/data.json`);
  let productRoute = await menuRoutes.json();
  productRoute = productRoute.find((i) => {
    return i.isProduct;
  });
  const dataPaths = [];

  productRoute.children.forEach((i) => {
    if (i.children) {
      i.children.forEach((c) => {
        dataPaths.push(c);
      });
    } else {
      dataPaths.push(i);
    }
  });
  for (const i of dataPaths) {
    dataUrls[i.path] = `/assets/product${i.path}/data.json`;
    const json = await fetch(`/assets/product${i.path}/data.json`);
    try {
      const source = await json.json();
      res[i.path] = source;
    } catch (e) {
      console.log(e);
    }
  }
  return res;
}
