var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:(_vm.$route.path !== '/' || _vm.hasBg || _vm.showSearch) && 'has-bg'},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"logo"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
            eventType: 'index_top_menu_btn_click',
            params: {
              index_top_menu_btn_name: 'logo',
            },
          }),expression:"{\n            eventType: 'index_top_menu_btn_click',\n            params: {\n              index_top_menu_btn_name: 'logo',\n            },\n          }"}],attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$router.push('/')}}},[(_vm.$route.path !== '/' || _vm.hasBg || _vm.showSearch)?_c('img',{attrs:{"src":"/assets/menu/img/logo2-red.png","alt":"B2BGO logo"}}):_c('img',{attrs:{"src":"/assets/menu/img/logo2-white.png","alt":"B2BGO logo"}})])]),(!_vm.isMobile)?_c('div',{staticClass:"menu-block justify-center"},[_c('ul',{staticClass:"menu-ul"},_vm._l((_vm.menuList),function(menu){return _c('li',{key:menu.name,staticClass:"menu-li"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
                  eventType: 'index_top_menu_btn_click',
                  params: {
                    index_top_menu_btn_name: menu.name,
                  },
                }),expression:"{\n                  eventType: 'index_top_menu_btn_click',\n                  params: {\n                    index_top_menu_btn_name: menu.name,\n                  },\n                }"}],class:['menu-a', menu.type && 'max', menu.children.length && 'min'],attrs:{"href":menu.path}},[_vm._v(_vm._s(menu.name)),(menu.children.length)?_c('span',{staticClass:"iconfont icon-arrowdown"}):_vm._e()]),(menu.children.length && !menu.type)?_c('div',{staticClass:"menu-child-min"},[_c('ul',{staticClass:"child-ul"},_vm._l((menu.children),function(child){return _c('li',{key:child.name,staticClass:"child-li"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
                      eventType: 'index_top_menu_sub_btn_click',
                      params: {
                        index_top_menu_btn_name: child.name,
                      },
                    }),expression:"{\n                      eventType: 'index_top_menu_sub_btn_click',\n                      params: {\n                        index_top_menu_btn_name: child.name,\n                      },\n                    }"}],attrs:{"href":child.path}},[_vm._v(_vm._s(child.name))])])}),0)]):_vm._e(),(menu.type)?_c('div',{staticClass:"menu-child-max"},[_c('div',{staticClass:"menu-child-inner"},_vm._l((menu.children),function(child){return _c('div',{key:child.name,staticClass:"col"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
                      eventType: 'index_top_product_btn_click',
                      params: {
                        industry_name: child.name,
                      },
                    }),expression:"{\n                      eventType: 'index_top_product_btn_click',\n                      params: {\n                        industry_name: child.name,\n                      },\n                    }"}],staticClass:"col-h",attrs:{"href":child.path,"target":"_blank"}},[_vm._v(_vm._s(child.name)),_c('span',{staticClass:"iconfont icon-arrowright"})]),_c('ul',{staticClass:"col-ul"},_vm._l((child.children),function(child_li){return _c('li',{key:child_li.name},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
                          eventType: 'index_top_product_btn_click',
                          params: {
                            industry_name: child.name,
                            subindustry_name: child_li.name,
                          },
                        }),expression:"{\n                          eventType: 'index_top_product_btn_click',\n                          params: {\n                            industry_name: child.name,\n                            subindustry_name: child_li.name,\n                          },\n                        }"}],attrs:{"href":child_li.path,"target":"_blank"}},[_vm._v(_vm._s(child_li.name))])])}),0)])}),0)]):_vm._e()])}),0)]):_vm._e(),_c('div',{staticClass:"icon-block"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
            eventType: 'index_top_menu_btn_click',
            params: {
              index_top_menu_btn_name: '搜索',
            },
          }),expression:"{\n            eventType: 'index_top_menu_btn_click',\n            params: {\n              index_top_menu_btn_name: '搜索',\n            },\n          }"}],staticClass:"icon-item",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.handleShowSearch(!_vm.showSearch)}}},[_c('span',{staticClass:"iconfont icon-search"})]),(_vm.showSearch)?_c('a',{staticClass:"icon-item",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.handleShowSearch(false)}}},[_c('span',{staticClass:"iconfont icon-guanbi"})]):[(_vm.isMobile && !_vm.showMenu)?_c('div',{staticClass:"icon-item",on:{"click":function($event){_vm.showMenu = true}}},[_c('span',{staticClass:"iconfont icon-caidan"})]):_vm._e(),(_vm.isMobile && _vm.showMenu)?_c('div',{staticClass:"icon-item",on:{"click":function($event){_vm.showMenu = false}}},[_c('span',{staticClass:"iconfont icon-guanbi"})]):_vm._e()]],2),_c('div',{class:['menu-block-m', _vm.showMenu && 'cur']},[_c('div',{staticClass:"menu-parent"},_vm._l((_vm.menuList),function(menu,index){return _c('div',{key:menu.name},[(menu.children.length)?_c('a',{directives:[{name:"track",rawName:"v-track",value:({
                eventType: 'index_top_menu_btn_click',
                params: {
                  index_top_menu_btn_name: menu.name,
                },
              }),expression:"{\n                eventType: 'index_top_menu_btn_click',\n                params: {\n                  index_top_menu_btn_name: menu.name,\n                },\n              }"}],class:[_vm.parentIndex === index && 'cur'],attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.parentIndex = index}}},[_vm._v(_vm._s(menu.name))]):_c('a',{attrs:{"href":menu.path}},[_vm._v(_vm._s(menu.name))])])}),0),_c('div',{staticClass:"menu-child"},_vm._l((_vm.menuList),function(menu,index){return _c('div',{key:index + menu.name,class:['child-item', _vm.parentIndex === index && 'cur']},[(menu.children.length && menu.path)?_c('div',{staticClass:"parent"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
                  eventType: 'index_top_menu_btn_click',
                  params: {
                    index_top_menu_btn_name: menu.name,
                  },
                }),expression:"{\n                  eventType: 'index_top_menu_btn_click',\n                  params: {\n                    index_top_menu_btn_name: menu.name,\n                  },\n                }"}],attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$router.push(menu.path)}}},[_vm._v(_vm._s(menu.name))])]):(menu.children.length)?_c('div',{staticClass:"parent"},[_vm._v(_vm._s(menu.name))]):_vm._e(),(menu.children.length)?_c('ul',{staticClass:"child-ul"},_vm._l((menu.children),function(child,idx){return _c('li',{key:child.name,class:['child-li', _vm.childIndex === idx && 'cur']},[_c('div',{staticClass:"th"},[(child.children)?_c('a',{directives:[{name:"track",rawName:"v-track",value:({
                        eventType: 'index_top_menu_sub_btn_click',
                        params: {
                          index_top_menu_btn_name: child.name,
                        },
                      }),expression:"{\n                        eventType: 'index_top_menu_sub_btn_click',\n                        params: {\n                          index_top_menu_btn_name: child.name,\n                        },\n                      }"}],attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.childIndex = _vm.childIndex === idx ? '' : idx}}},[_c('div',[_vm._v(_vm._s(child.name))]),_c('div',{staticClass:"iconfont icon-arrowdown"})]):_c('a',{attrs:{"href":child.path}},[_c('div',[_vm._v(_vm._s(child.name))]),_c('div',{staticClass:"iconfont icon-arrowright"})])]),_c('div',{staticClass:"td"},_vm._l((child.children),function(sublink){return _c('a',{directives:[{name:"track",rawName:"v-track",value:({
                        eventType: 'index_top_menu_sub_btn_click',
                        params: {
                          index_top_menu_btn_name: sublink.name,
                        },
                      }),expression:"{\n                        eventType: 'index_top_menu_sub_btn_click',\n                        params: {\n                          index_top_menu_btn_name: sublink.name,\n                        },\n                      }"}],key:sublink.name,attrs:{"href":sublink.path,"target":"_blank"}},[_vm._v(_vm._s(sublink.name))])}),0)])}),0):_vm._e()])}),0)])])]),(_vm.$route.path !== '/' || _vm.hasBg)?_c('div',{staticClass:"page-header-cover"}):_vm._e(),_c('search',{attrs:{"visible":_vm.showSearch}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }