<!-- 企业通用 -->
<template>
  <div :class="visible ? 'page-search cur' : 'page-search'">
    <div class="search-box">
      <i></i>
      <input v-model="keyword" placeholder="请输入关键字搜索" type="text" @blur="handleSearch" @keydown.enter="handleSearch" />
      <a v-if="keyword !== ''" href="javascript:" class="delete" @click="deleteKeyWord"></a>
    </div>
    <template v-if="value">
      <div class="search-hd">
        <span>“{{ value }}”</span>的搜索结果
      </div>
      <div v-if="searchResult.length" class="list-block">
        <a :href="item.url || item.link" v-for="(item, index) in searchResult" :key="item.tit + index">
          <div class="item">
            <div class="pic">
              <img :src="`/assets/${item.path}/img/${item.img}`" />
            </div>
            <div class="info">
              <div class="title" v-html="getTit(item.tit)"></div>
              <div class="desc">{{ item.des }}</div>
            </div>
          </div>
        </a>
      </div>
      <div v-else class="search-tip">
        {{ loading ? '搜索中。。。' : '暂无搜索结果' }}
      </div>
    </template>
  </div>
</template>
<script>
import list from './assets/data.js';
import { searchKeyWord } from '../../utils/search.js';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list,
      searchResult: [],
      pageSize: 10,
      current: 1,
      loading: false,
      keyword: '',
      value: '',
    };
  },
  computed: {
    currentSource() {
      if (this.searchResult.length === 0) {
        return [];
      }
      const max = Math.ceil(this.searchResult.length / 10);
      return this.searchResult.slice(10 * (this.current - 1), this.current === max ? this.searchResult.length : 10 * this.current);
    },
  },
  methods: {
    async handleSearch() {
      this.value = this.keyword;
      if (!this.keyword) {
        return;
      }
      this.loading = true;
      const res = await searchKeyWord(this.value);
      this.searchResult.splice(0, this.searchResult.length, ...res);
      this.current = 1;
      this.loading = false;
    },
    deleteKeyWord() {
      this.keyword = '';
      this.value = '';
    },
    getTit(txt) {
      return txt.split(this.value).join(`<b style='color:#e6002e'>${this.value}</b>`);
    },
  },
};
</script>
<style scoped lang="less">
.page-search {
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  min-height: 180px;
  max-height: calc(100vh - 56px);
  z-index: 99999;
  box-sizing: border-box;
  padding: 3%;
  padding: 48px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(8, 21, 55, 0.07);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  overflow: auto;
  &.cur {
    opacity: 1;
    pointer-events: auto;
  }
  .search-tip {
    font-size: 14px;
    text-align: center;
    line-height: 2;
    padding: 50px 0;
    color: #999;
  }
  .search-box {
    display: flex;
    width: 60%;
    margin: 0 auto;
    height: 40px;
    border-bottom: 1px solid #ccc;
    align-items: center;
    margin-bottom: 40px;
    i {
      width: 20px;
      height: 20px;
      margin-right: 15px;
      flex-shrink: 0;
      background-size: 100% 100%;
      background-image: url('./../../assets/img/search.png');
    }
    input {
      height: 38px;
      line-height: 38px;
      font-size: 16px;
      flex-grow: 1;
    }
    a {
      width: 16px;
      height: 16px;
      opacity: 0.3;
      margin-left: 20px;
      flex-shrink: 0;
      background-size: 100% 100%;
      background-image: url('./../../assets/img/close-bold.png');
    }
  }
  .search-hd {
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
    width: calc(85.652vw);
    max-width: 1444px;
    margin: 0 auto;
    span {
      color: #9197ae;
    }
  }
  .list-block {
    padding: 16px 0;
    width: calc(85.652vw);
    max-width: 1444px;
    margin: 0 auto;
    .item {
      display: flex;
      color: #2a2a2a;
      padding: 30px 0;
      border-bottom: 1px solid #dedfe0;
      transition: all 0.5s;

      &:hover {
        img {
          transform: scale(1.05);
        }
        .info {
          color: #e6002e;
          .desc {
            color: #e6002e;
          }
        }
      }
      .pic {
        width: 236px;
        flex-shrink: 0;
        img {
          width: 100%;
          transition: all 0.5s;
        }
        padding-right: 16px;
      }
      .info {
        flex: 1;
        .title {
          font-weight: 700;
          font-size: 18px;
          line-height: 1;
          padding-top: 4px;
          b {
            color: #e6002e;
          }
        }
        .desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #666;
          padding-top: 13px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    min-height: calc(100vh - 80px);
    padding: 0.16rem;
    .search-hd {
      font-size: 0.18rem;
    }
    .search-box {
      display: flex;
      width: 80%;
      margin: 0 auto;
      height: 0.3rem;
      border-bottom: thin solid #ccc;
      align-items: center;
      margin-bottom: 0.2rem;
      i {
        width: 0.15rem;
        height: 0.15rem;
        flex-shrink: 0;
        background-size: 100% 100%;
        background-image: url('./../../assets/img/search.png');
        margin-right: 0.15rem;
      }
      input {
        appearance: none;
        box-shadow: none;
        height: 0.3rem;
        line-height: 0.3rem;
        font-size: 0.14rem;
        flex-grow: 1;
      }
      a {
        width: 0.15rem;
        height: 0.15rem;
        flex-shrink: 0;
        background-size: 100% 100%;
        background-image: url('./../../assets/img/close-bold.png');
        opacity: 0.5;
        margin-left: 0.15rem;
      }
    }
    .list-block {
      margin: 0;
      padding: 0;
      .item {
        display: block;
        .pic {
          width: 100%;
          padding: 0;
        }
        .info {
          padding-top: 0.12rem;
          .title {
            line-height: 1.3;
            b {
              color: #e6002e;
            }
          }
        }
      }
    }
  }
}
</style>
