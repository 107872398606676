<template>
  <div class="page-footer" v-if="list.length || footer">
    <div class="coopunit" v-if="isMobile">
      <div :class="['item', curIndex === index && 'cur']" v-for="(item, index) in org_list" :key="item.type">
        <h3 @click="curIndex = curIndex === index ? '' : index">
          <div>{{ item.type }}</div>
          <div class="iconfont icon-arrowdown"></div>
        </h3>
        <ul>
          <li v-for="(sub, idx) in item.list" :key="idx + sub.link">
            <a
              :href="sub.link"
              target="_blank"
              v-track="{
                eventType: 'index_organization_website_click',
                params: {
                  content_name: sub.name,
                },
              }"
              >{{ sub.name }}</a
            >
          </li>
        </ul>
      </div>
      <div :class="['item', curIndex === 'webList' && 'cur']">
        <h3 @click="curIndex = curIndex === 'webList' ? '' : 'webList'">
          <div>合作网站</div>
          <div class="iconfont icon-arrowdown"></div>
        </h3>
        <ul>
          <template v-for="(sub, idx) in webList">
            <li :key="idx + sub.link" v-if="idx < 8">
              <a
                :href="sub.link"
                target="_blank"
                v-track="{
                  eventType: 'index_cooperate_website_click',
                  params: {
                    content_name: sub.name,
                  },
                }"
                >{{ sub.name }}</a
              >
            </li>
          </template>
          <li v-if="footer.more_website.show">
            <a
              href="/co-org"
              target="_blank"
              v-track="{
                eventType: 'index_cooperate_website_click',
                params: {
                  content_name: footer.more_website.name,
                },
              }"
              >{{ footer.more_website.name }}<span class="iconfont icon-arrowright"></span
            ></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="hav-link" v-if="!isMobile">
      <div class="inner" v-for="item in org_list" :key="item.type">
        <div class="h3">{{ item.type }}</div>
        <ul>
          <li v-for="(sub, idx) in item.list" :key="idx + sub.link">
            <a
              :href="sub.link"
              target="_blank"
              v-track="{
                eventType: 'index_organization_website_click',
                params: {
                  content_name: sub.name,
                },
              }"
              >{{ sub.name }}</a
            >
          </li>
        </ul>
      </div>
      <div class="inner">
        <div class="h3">合作网站</div>
        <ul>
          <template v-for="(sub, idx) in webList">
            <li :key="idx + sub.link" v-if="idx < 8">
              <a
                :href="sub.link"
                target="_blank"
                v-track="{
                  eventType: 'index_cooperate_website_click',
                  params: {
                    content_name: sub.name,
                  },
                }"
                >{{ sub.name }}</a
              >
            </li>
          </template>
          <li v-if="footer.more_website.show">
            <a
              :href="footer.more_website.link"
              target="_blank"
              v-track="{
                eventType: 'index_cooperate_website_click',
                params: {
                  content_name: footer.more_website.name,
                },
              }"
              >{{ footer.more_website.name }}<span class="iconfont icon-arrowright"></span
            ></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="copyright">
      <div class="inner">
        <div class="text">
          <p>{{ footer.copyright }}</p>
          <span>{{ footer.beian }}</span>
        </div>
        <div class="link">
          <div>
            <template v-for="(item, index) in footer.links">
              <a :href="item.link" :key="index">{{ item.name }}</a>
              <span v-if="index < footer.links.length - 1" :key="item.name + index">|</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      org_list: [],
      list: [],
      webList: [],
      isMobile: window.innerWidth < 991,
      curIndex: '',
      footer: {
        more_website: {
          link: '/co-org',
          name: '更多网站',
          show: false,
        },
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.query();
    }, 1000);
  },
  methods: {
    query() {
      fetch('/assets/org/org.json?' + Math.random()).then((d) => {
        d.json().then((res) => {
          this.list = res.list;
        });
      });
      fetch('/assets/co-org/data.json').then((dd) => {
        dd.json().then((_res) => {
          this.webList = _res.webList;
        });
      });
      fetch('/assets/footer/data.json?' + Math.random()).then((d) => {
        d.json().then((res) => {
          this.footer = res;
        });
      });
      fetch('/assets/footer/data_org.json?' + Math.random()).then((d) => {
        d.json().then((res) => {
          this.org_list = res.list;
        });
      });
    },
  },
};
</script>
<style scoped lang="less">
.page-footer {
  color: #fff;
  text-align: center;
  background: #141414;

  .coopunit {
    padding: 60px 0;
    width: 85.652%;
    max-width: 1424px;
    margin: 0 auto;
    display: flex;
    @media (max-width: 991px) {
      padding: 0;
      display: block;
      width: 100%;
    }

    .item {
      width: 25%;
      text-align: left;

      h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 1;
        padding-bottom: 24px;
      }

      ul {
        // padding: 0 15px;
      }

      li {
        padding: 12px 0;

        a {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
          &:hover {
            color: #e6002e;
            background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
      }
      @media (max-width: 991px) {
        width: 100%;
        h3 {
          font-size: 0.16rem;
          padding: 0.16rem;
          color: #d5d5d5;
          border-bottom: 0.5px solid #383838;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .iconfont {
            transition: all 0.3s;
          }
        }
        ul,
        li {
          padding: 0;
          a {
            font-size: 0.12rem;
            padding: 0.12rem 0.16rem;
            color: #999;
            display: block;
            background: #191919;
          }
        }
        ul {
          height: 0;
          opacity: 0;
          transition: opacity 0.5s;
          visibility: hidden;
        }
        &.cur {
          h3 {
            color: #e6002e;
            background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            .iconfont {
              transform: rotate(180deg);
            }
          }
          ul {
            visibility: visible;
            height: auto;
            opacity: 1;
            transition: opacity 0.5s;
          }
        }
      }
    }
  }
  .hav-link {
    background: #141414;
    padding-bottom: 30px;
    line-height: 22px;
    text-align: left;
    .inner {
      width: 85.652%;
      max-width: 1424px;
      margin: 0 auto;
    }
    .h3 {
      font-weight: 400;
      font-size: 18px;
      line-height: 1;
      padding-top: 60px;
    }

    li {
      padding: 12px 24px 0 0;
      display: inline-block;

      a {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
        &:hover {
          color: #e6002e;
          background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
    }
  }
  .copyright {
    background: #191919;
    padding: 60px 0;
    line-height: 22px;
    .inner {
      width: 85.652%;
      max-width: 1424px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .text {
        text-align: left;
        p {
          color: rgba(255, 255, 255, 0.6);
        }
        span {
          font-size: 12px;
        }
      }
      .link {
        text-align: right;
        a {
          color: #fff;
          &:hover {
            background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: #e6002e;
          }
        }
        span {
          vertical-align: 1px;
          padding: 0 12px;
        }
      }
    }
    @media (max-width: 991px) {
      padding: 0.24rem 0.16rem;
      .inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        color: #ababab;

        .text {
          // float: right;
        }
        .link {
          padding-bottom: 0.18rem;
          text-align: left;
          a {
            color: #ababab;
          }
        }
      }
    }
  }
}
</style>
