<template>
  <div :class="($route.path !== '/' || hasBg || showSearch) && 'has-bg'">
    <div class="page-header">
      <div class="inner">
        <div class="logo">
          <a href="javascript:;" @click="$router.push('/')" v-track="{
            eventType: 'index_top_menu_btn_click',
            params: {
              index_top_menu_btn_name: 'logo',
            },
          }">
            <img v-if="$route.path !== '/' || hasBg || showSearch" src="/assets/menu/img/logo2-red.png"
              alt="B2BGO logo" />
            <img v-else src="/assets/menu/img/logo2-white.png" alt="B2BGO logo" />
          </a>
        </div>
        <div class="menu-block justify-center" v-if="!isMobile">
          <ul class="menu-ul">
            <li class="menu-li" v-for="menu in menuList" :key="menu.name">
              <a :class="['menu-a', menu.type && 'max', menu.children.length && 'min']" :href="menu.path"
                v-track="{
                  eventType: 'index_top_menu_btn_click',
                  params: {
                    index_top_menu_btn_name: menu.name,
                  },
                }">{{ menu.name }}<span v-if="menu.children.length"
                  class="iconfont icon-arrowdown"></span></a>
              <div class="menu-child-min" v-if="menu.children.length && !menu.type">
                <ul class="child-ul">
                  <li class="child-li" v-for="child in menu.children" :key="child.name">
                    <a :href="child.path" v-track="{
                      eventType: 'index_top_menu_sub_btn_click',
                      params: {
                        index_top_menu_btn_name: child.name,
                      },
                    }">{{ child.name }}</a>
                  </li>
                </ul>
              </div>
              <div class="menu-child-max" v-if="menu.type">
                <div class="menu-child-inner">
                  <div class="col" v-for="child in menu.children" :key="child.name">
                    <a class="col-h" :href="child.path" target="_blank" v-track="{
                      eventType: 'index_top_product_btn_click',
                      params: {
                        industry_name: child.name,
                      },
                    }">{{ child.name }}<span class="iconfont icon-arrowright"></span></a>
                    <ul class="col-ul">
                      <li v-for="child_li in child.children" :key="child_li.name">
                        <a :href="child_li.path" target="_blank" v-track="{
                          eventType: 'index_top_product_btn_click',
                          params: {
                            industry_name: child.name,
                            subindustry_name: child_li.name,
                          },
                        }">{{ child_li.name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="icon-block">
          <a class="icon-item" href="javascript:" @click="handleShowSearch(!showSearch)" v-track="{
            eventType: 'index_top_menu_btn_click',
            params: {
              index_top_menu_btn_name: '搜索',
            },
          }">
            <span class="iconfont icon-search"></span></a>
          <a class="icon-item" href="javascript:" @click="handleShowSearch(false)" v-if="showSearch">
            <span class="iconfont icon-guanbi"></span>
          </a>
          <template v-else>
            <div class="icon-item" @click="showMenu = true" v-if="isMobile && !showMenu">
              <span class="iconfont icon-caidan"></span>
            </div>
            <div class="icon-item" @click="showMenu = false" v-if="isMobile && showMenu">
              <span class="iconfont icon-guanbi"></span>
            </div>
          </template>
        </div>

        <div :class="['menu-block-m', showMenu && 'cur']">
          <div class="menu-parent">
            <div v-for="(menu, index) in menuList" :key="menu.name">
              <a :class="[parentIndex === index && 'cur']" v-track="{
                eventType: 'index_top_menu_btn_click',
                params: {
                  index_top_menu_btn_name: menu.name,
                },
              }" v-if="menu.children.length" @click="parentIndex = index" href="javascript:;">{{ menu.name
}}</a>
              <a v-else :href="menu.path">{{ menu.name }}</a>
            </div>
          </div>
          <div class="menu-child">
            <div :class="['child-item', parentIndex === index && 'cur']" v-for="(menu, index) in menuList"
              :key="index + menu.name">
              <div class="parent" v-if="menu.children.length && menu.path">
                <a href="javascript:;" @click="$router.push(menu.path)" v-track="{
                  eventType: 'index_top_menu_btn_click',
                  params: {
                    index_top_menu_btn_name: menu.name,
                  },
                }">{{ menu.name }}</a>
              </div>
              <div class="parent" v-else-if="menu.children.length">{{ menu.name }}</div>
              <ul class="child-ul" v-if="menu.children.length">
                <li :class="['child-li', childIndex === idx && 'cur']" v-for="(child, idx) in menu.children"
                  :key="child.name">
                  <div class="th">
                    <a v-if="child.children" @click="childIndex = childIndex === idx ? '' : idx"
                      href="javascript:;" v-track="{
                        eventType: 'index_top_menu_sub_btn_click',
                        params: {
                          index_top_menu_btn_name: child.name,
                        },
                      }">
                      <div>{{ child.name }}</div>
                      <div class="iconfont icon-arrowdown"></div>
                    </a>
                    <a v-else :href="child.path">
                      <div>{{ child.name }}</div>
                      <div class="iconfont icon-arrowright"></div>
                    </a>
                  </div>
                  <div class="td">
                    <a :href="sublink.path" target="_blank" v-for="sublink in child.children"
                      :key="sublink.name" v-track="{
                        eventType: 'index_top_menu_sub_btn_click',
                        params: {
                          index_top_menu_btn_name: sublink.name,
                        },
                      }">{{ sublink.name }}</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-header-cover" v-if="$route.path !== '/' || hasBg"></div>
    <search :visible="showSearch" />
  </div>
</template>
<script>
import search from '@/views/search/index';
let scrollTop = 0;
export default {
  props: {},
  components: {
    search,
  },
  watch: {
    $route() {
      this.resetData();
    },
  },
  data() {
    return {
      hasBg: false,
      menuList: [],
      isMobile: window.innerWidth < 991,
      parentIndex: '',
      childIndex: '',
      showMenu: false,
      showSearch: false,
    };
  },
  created() {
    window.addEventListener('scroll', this.windowScrolistener);
  },
  mounted() {
    this.query();
  },
  methods: {
    windowScrolistener: function () {
      scrollTop = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 380 && !this.hasBg) {
        this.hasBg = true;
      }
      if (scrollTop < 380 && this.hasBg) {
        this.hasBg = false;
      }
    },
    query() {
      fetch('/assets/menu/data.json?' + Math.random()).then((d) => {
        d.json().then((res) => {
          this.menuList = res;
        });
      });
    },
    resetData: function () {
      this.parentIndex = '';
      this.childIndex = '';
      this.showMenu = false;
    },
    handleShowSearch(flag) {
      this.showSearch = flag;
      this.$emit('showSearch', flag);
    },
    trackingLink(e, eventType, options, url) {
      e.preventDefault();
      this.$hwa.sendAction(eventType, options);
      window.location.href = url;
    },
  },
};
</script>
<style scoped lang="less">
.has-bg {
  .page-header-cover {
    display: block;
  }

  .page-header {
    background: #fff;
    border-bottom: 1px solid #dedfe0;

    .inner {

      .icon-search,
      .icon-caidan,
      .icon-guanbi {
        font-size: 20px;
        color: #2a2a2a;
      }

      .menu-ul {
        .menu-li {
          &:hover {
            .menu-a {
              color: #e6002e !important;
            }
          }

          .menu-a {
            color: #2a2a2a !important;
          }
        }
      }
    }
  }
}

.page-header-cover {
  width: 100%;
  height: 57.5px;
  display: none;
}

.page-header {
  // background: #fff;
  color: #2a2a2a;
  font-size: 14px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

  @media (max-width: 991px) {
    // background: none;
  }

  .inner {
    // width: 85.652%;
    max-width: 1424px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

    .logo {
      padding: 6px 0;
      margin-left: 16px;

      img {
        height: 40px;
      }
    }

    @media (max-width: 991px) {
      width: 100%;
      height: 56px;

      .logo {
        padding: 8px 16px;
        margin-left: 0;

        img {
          height: 28px;
        }
      }
    }

    .icon-block {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      min-width: 100px;
      justify-content: flex-end;

      .icon-item {
        width: 40px;
        // padding: 0 16px;
      }
    }

    .icon-search,
    .icon-caidan,
    .icon-guanbi {
      font-size: 20px;
      color: #fff;
    }

    .menu-block {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: center;

      .menu-ul {
        .menu-li {
          display: inline-block;
          position: relative;

          // &::before {
          //   display: block;
          //   content: '';
          //   width: 0;
          //   position: fixed;
          //   top: 57px;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   height: 1px;
          //   background-color: #e6002e;
          //   transition: all 0.5s;
          // }
          &:hover {
            &::before {
              width: 100%;
            }

            .menu-a.min::before,
            .menu-child-max,
            .menu-child-min {
              visibility: visible;
              opacity: 1;
            }

            .iconfont {
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
            }

            .menu-a {
              // color: #e6002e;
            }
          }

          .menu-a {
            display: inline-block;
            line-height: 56px;
            padding: 0 16px;
            position: relative;
            color: #fff;

            &:hover {
              color: #fff;
            }

            &.min {
              &::before {
                display: block;
                content: '';
                position: absolute;
                top: 57px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                border: 6px solid transparent;
                border-bottom: 6px solid rgba(255, 255, 255, 0.8);
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
                visibility: hidden;
                opacity: 0;
              }
            }

            &.max {
              &::before {
                border-bottom: 6px solid #e6002e;
                top: auto;
                bottom: -1px;
              }
            }

            .iconfont {
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
              font-size: 8px;
              display: inline-block;
            }
          }

          .menu-child-min {
            width: 146px;
            top: 57px;
            -webkit-transition: all 0.5s;
            transition: all 0.5s;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            padding-top: 12px;

            .child-ul {
              background-color: rgba(255, 255, 255, 0.8);
              -webkit-backdrop-filter: blur(10px);
              backdrop-filter: blur(10px);
              border-radius: 8px;
              padding: 4px 0;

              a {
                display: block;
                font-weight: 400;
                font-size: 14px;
                line-height: 1;
                padding: 12px 0;
                color: #2a2a2a;

                &:hover {
                  color: #e6002e;
                }
              }
            }
          }
        }
      }

      .menu-child-max {
        position: fixed;
        width: 100%;
        top: 57px;
        left: 0;
        background: #fff;
        padding-top: 60px;
        // border-top: 1px solid #e6002e;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        visibility: hidden;
        opacity: 0;

        background: rgba(255, 255, 255, 0.8);
        -webkit-backdrop-filter: blur(48px);
        backdrop-filter: blur(48px);

        .menu-child-inner {
          width: 85.652%;
          max-width: 1424px;
          margin: 0 auto;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          text-align: left;

          .col {
            width: 16.66666667%;
            padding-bottom: 52px;

            .col-h {
              font-weight: 700;
              font-size: 18px;
              line-height: 18px;
              color: #2a2a2a;

              &:hover {
                color: #e6002e;
              }

              .iconfont {
                font-size: 16px;
                line-height: 18px;
                vertical-align: top;
                padding-left: 10px;
                font-weight: bold;
              }
            }

            .col-ul {
              padding-top: 16px;

              li {
                padding: 8px 0;

                a {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 14px;
                  color: #2a2a2a;

                  &:hover {
                    color: #e6002e;
                  }
                }
              }
            }
          }
        }
      }
    }

    .menu-block-m {
      // display: none;
      position: fixed;
      width: 100%;
      top: 56px;
      left: -100%;
      bottom: 0;
      background: #f7f8fa;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      opacity: 0;
      -webkit-transition: opacity 0.5s;
      transition: opacity 0.5s;

      &.cur {
        left: 0;
        opacity: 1;
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
      }

      a {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #2a2a2a;
        padding: 16px;
      }

      .menu-parent {
        min-width: 40%;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        height: 100%;
        overflow-y: auto;
        background: #fff;
      }

      .menu-child {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        height: 100%;
        overflow-y: auto;

        .parent {
          border-bottom: 0.5px solid #dedfe0;
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: #2a2a2a;
          padding: 16px;

          a {
            padding: 0;
          }
        }

        .child-ul {
          .child-li {
            .th {
              a {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
              }
            }

            .td {
              a {
                font-size: 12px;
                line-height: 12px;
                padding: 12px 0 12px 32px;
                color: #666;
              }
            }
          }
        }
      }

      .menu-parent {
        .cur {
          color: #e6002e;
        }
      }

      .child-item {
        display: none;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;

        &.cur {
          display: block;
        }
      }

      .child-li {
        -webkit-transition: all 0.3s;
        transition: all 0.3s;

        .td {
          height: 0;
          opacity: 0;
          visibility: hidden;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
        }

        &.cur {
          .th {
            a {
              color: #e6002e;
            }

            .iconfont {
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
              -webkit-text-fill-color: #2a2a2a;
            }
          }

          .td {
            height: auto;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
</style>
