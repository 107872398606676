import Vue from "vue";
import VueRouter from "vue-router";
import product from "./product";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    name: "index",
    path: "/",
    component: () => import("../views/index"),
    meta: {
      title: "828 B2B企业节 | B2BGO官网",
      content: {
        keywords: '华为云b2bgo,B2B企业节,828,b2bgo官方网站,828B2B',
        description: 'B2BGO是华为联合生态伙伴发起的服务于B2B企业的数字赋能平台，涉及到协同办公、营销获客、大数据应用、AI、基础软件应用、智慧办公、AR协作、区块链、企业营销及管理等场景，涵盖了制造、互联网、文娱、电商、新零售、企业服务等领域，帮助中小企业成就好生意，成为好企业。828 B2B企业节是B2BGO举办的官方活动，旨在为中小企业搭建一个创新发展平台。'
      }
    },
  },
  {
    name: "index",
    path: "/index",
    component: () => import("../views/index"),
    meta: {
      title: "828 B2B企业节 | B2BGO官网",
      content: {
        keywords: '华为云b2bgo,B2B企业节,828,b2bgo官方网站,828B2B',
        description: 'B2BGO是华为联合生态伙伴发起的服务于B2B企业的数字赋能平台，涉及到协同办公、营销获客、大数据应用、AI、基础软件应用、智慧办公、AR协作、区块链、企业营销及管理等场景，涵盖了制造、互联网、文娱、电商、新零售、企业服务等领域，帮助中小企业成就好生意，成为好企业。828 B2B企业节是B2BGO举办的官方活动，旨在为中小企业搭建一个创新发展平台。'
      }
    },
  },
  {
    name: "extensionIndex",
    path: "/extension",
    component: () => import("../views/extension"),
    meta: {
      title: "828 B2B企业节在线活动 | B2BGO官网",
      content: {
        title: '828 B2B企业节在线活动 | B2BGO官网',
        description: '828 B2B企业节活动助力企业“成就好生意，成为好企业”，是国内首个基于数字赋能的B2B企业节，分享数字化转型优秀实践及案例。'
      }
    },
  },
  {
    name: "search",
    path: "/search",
    component: () => import("../views/search"),
    meta: {
      title: "搜索",
    },
  },
  {
    name: "momentVideos",
    path: "/moment/videos",
    component: () => import("../views/moment/videos"),
    meta: {
      title: "828 B2B企业节视频集锦 | B2BGO官网",
      content: {
        title: '828 B2B企业节视频集锦 | B2BGO官网',
        description: '往期828 B2B企业节活动视频锦集，查看更多过往相关活动视频，了解更多活动详情。'
      }
    },
  },
  {
    name: "momentPics",
    path: "/moment/pics",
    component: () => import("../views/moment/pics"),
    meta: {
      title: "828 B2B企业节现场图片集锦 | B2BGO官网",
      content: {
        title: '828 B2B企业节现场图片集锦 | B2BGO官网',
        description: '这里汇集了828 B2B企业节活动精彩瞬间，查看更多相关活动现场图锦。'
      }
    },
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("../views/privacy-policy"),
    meta: {
      title: "隐私政策声明",
    },
  },
  {
    name: "news",
    path: "/news",
    component: () => import("../views/news"),
    meta: {
      title: "新闻资讯",
      dataUrl: "/assets/news/data.json",
    },
  },
  {
    name: "opportunity",
    path: "/opportunity.html",
    component: () => import("../views/week/opportunity.vue"),
    meta: {
      title: "商机交流周",
    },
  },
  {
    name: "growth",
    path: "/growth.html",
    component: () => import("../views/week/growth.vue"),
    meta: {
      title: "成长加速周",
    },
  },
  {
    name: "experience",
    path: "/experience.html",
    component: () => import("../views/week/digital.vue"),
    meta: {
      title: "经验传递周",
    },
  },
  {
    name: "enabling",
    path: "/enabling.html",
    component: () => import("../views/week/enabling.vue"),
    meta: {
      title: "创新赋能周",
    },
  },
  {
    name: "org",
    path: "/org",
    component: () => import("../views/org"),
    meta: {
      title: "828 B2B企业节发起单位 | B2BGO官网",
      content: {
        title: '828 B2B企业节发起单位 | B2BGO官网',
        description: '828 B2B企业节发起单位是华为技术有限公司，联合发起的单位有8家，协作发起的公司有22家，支持的媒体有16家。'
      }
    },
  },
  {
    name: "co-org",
    path: "/co-org",
    component: () => import("../views/co-org"),
    meta: {
      title: "828 B2B企业节合作机构 | B2BGO官网",
      content: {
        title: '828 B2B企业节合作机构 | B2BGO官网',
        description: '828 B2B企业节携手合作伙伴、平台、各类机构共同服务中小企业，查看更多的合作企业及平台。'
      }
    },
  },
  {
    name: "ceremony",
    path: "/ceremony",
    component: () => import("../views/ceremony"),
    meta: {
      title: "启动仪式",
    },
  },
  {
    path: "/zjtx",
    component: () => import("../views/business-service/zjtx"),
    name: "zjtx",
    meta: {
      title: "专精特新",
    },
  },
  {
    path: "/startup",
    component: () => import("../views/business-service/startup"),
    name: "startup",
    meta: {
      title: "企业初创",
    },
  },
  {
    path: "/industry-ec",
    component: () => import("../views/business-service/industry-ec"),
    name: "industry-ec",
    meta: {
      title: "创新发展",
    },
  },
  {
    path: "/go-global",
    component: () => import("../views/business-service/go-global"),
    name: "go-global",
    meta: {
      title: "企业出海",
    },
  },
  {
    name: "case",
    path: "/case",
    component: () => import("../views/case"),
    meta: {
      title: "成功案例",
      dataUrl: "/assets/case/data.json",
    },
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("../views/policy"),
    meta: {
      title: "政策支持 | B2BGO官网",
      dataUrl: "/assets/policy/data.json",
      content: {
        title: '政策支持 | B2BGO官网',
        description: '828 B2B企业节政策支持及解读，获取更多相关政策文件。'
      }
    },
  },
  {
    name: "mutual-aid",
    path: "/mutual-aid",
    component: () => import("../views/mutual-aid"),
    meta: {
      title: "互助中心",
    },
  },
  {
    name: "about",
    path: "/about",
    component: () => import("../views/about"),
    meta: {
      title: "828 B2B企业节介绍 | B2BGO官网",
      content: {
        title: '828 B2B企业节介绍 | B2BGO官网',
        description: '2022年8月28日华为协同生态伙伴在贵安联合发起首届“828 B2B企业节”，节日主题是“成就好生意，成为好企业”。节日围绕商机促进、优品优惠、技术创新、品牌提升、经验分享、人才发展6大价值，企业节吉祥物是MR.BEE，联系邮箱huaweicloudcnmkt@huawei.com。'
      }
    },
  },
  {
    name: "events",
    path: "/events",
    component: () => import("../views/events"),
    redirect: "/events/announce",
    meta: {
      title: "精彩活动",
    },
    children: [
      {
        path: "live",
        component: () => import("../views/live/live"),
        name: "live",
        meta: {
          title: "828 B2B企业节直播间 | B2BGO官网",
          content: {
            title: '828 B2B企业节直播间 | B2BGO官网',
            description: '828 B2B企业节在线直播间，随时查看B2B在线相关视频及活动。'
          }
        },
      },
      {
        path: "announce",
        component: () => import("../views/events/announce"),
        name: "announce",
        meta: {
          title: "828 B2B企业节启动仪式 | B2BGO官网",
          content: {
            title: '828 B2B企业节启动仪式 | B2BGO官网',
            description: '828 B2B企业节启动仪式暨“对话828”特别策划活动、查看活动举办日期、嘉宾和议程安排。'
          }
        },
      },
      {
        path: "partner2022",
        component: () => import("../views/events/partner2022"),
        name: "partner2022",
        meta: {
          title: "华为云和TA的伙伴们2022",
        },
      },
      {
        path: "opening",
        component: () => import("../views/events/opening"),
        name: "opening",
        meta: {
          title: "开幕式",
        },
      },
      {
        path: "area",
        component: () => import("../views/events/area"),
        name: "area",
        meta: {
          title: "828地方站",
        },
        children: [
          {
            path: "/events/:id",
            component: () => import("../views/events/area"),
          },
        ],
      },
    ],
  },
  ...product,
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.name == "index" && to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

let head = document.getElementsByTagName('head');
let title = document.createElement('meta');
title.name = "title";
head[0].appendChild(title);
let keywords = document.createElement('meta');
keywords.name = "keywords";
head[0].appendChild(keywords);
let description = document.createElement('meta');
description.name = "description";
head[0].appendChild(description);
let link = document.createElement('link');
link.rel = "canonical";
head[0].appendChild(link)

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "\u200E";

  Vue.prototype.$hwa.sendPageview({
    title: to.meta.title,
    url: window.location.origin + to.fullPath,
  });

  if (to.meta.content) {
    if (to.meta.content.title) {
      document.querySelector('meta[name="title"]').setAttribute('content', to.meta.content.title)
    }
    if (to.meta.content.keywords) {
      document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    }
    if (to.meta.content.description) {
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    }
  }

  // document.title = to.meta.title

  link.href = "https://b2bgo.huaweicloud.com" + to.path;
  if (to.path == "/index") {
    link.href = "https://b2bgo.huaweicloud.com" + "/";
  }

  next();
});

export default router;
