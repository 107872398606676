<template>
  <div id="app">
    <div class="container" style="overflow: hidden">
      <page-header v-if="!isExtension" @showSearch="showSearch"></page-header>
      <router-view v-show="showPage || !isMobile" />
      <page-footer v-if="!isExtension" v-show="showPage || !isMobile"></page-footer>
    </div>
    <fixed-tool v-if="!isExtension"></fixed-tool>
  </div>
</template>
<script>
import pageHeader from '@/components/header';
import pageFooter from '@/components/footer';
import fixedTool from '@/components/fixedTool';
export default {
  components: {
    pageHeader,
    pageFooter,
    fixedTool,
  },
  data() {
    return {
      showPage: true,
      isMobile: document.documentElement.clientWidth < 768,
      isExtension: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        this.isExtension = val.name === 'extension' || val.name === 'extensionIndex';
      },
    },
  },
  methods: {
    showSearch(flag) {
      this.showPage = !flag;
    },
  },
  mounted() {},
};
</script>
<style src="./assets/iconfont/iconfont.css"></style>
<style lang="less" src="./assets/reset.less"></style>
<style lang="less" src="./assets/bootstrap-m.less" />
<style lang="less" src="./assets/base.less"></style>
